import * as React from "react"
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import "./styles.css";
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import Hero from '../images/charles-forerunner-3fPXt37X6UQ-unsplash.jpg';


const Heading = styled.h1`
display: none;
`;

const HeroSection = styled.section`
font-family: Poppins, sans-serif;
    height: 700px;

    @media screen and (max-width: 850px) {
      height: 500px;
    }

`;

const HeroImg = styled.img`
height: 700px;
width: 100%;
object-fit: cover;
opacity: 0.4;

@media screen and (max-width: 850px) {
  height: 500px;
}
`;

const Mission = styled.p`
display: block;
margin: 0px auto;
font-size: 25px;
max-width: 700px;
text-align: center;
vertical-align: middle;
position: relative;
top: -570px;
font-weight: 300;

@media screen and (max-width: 850px) {
max-width: 100%;
margin: 20px;
top: -470px;
font-size: 16px;

}
`;

const Emphasis = styled.span`
font-weight: bold;
    color: rgb(17, 37, 68);
    font-size: 32px;


    @media screen and (max-width: 850px) {
      font-size: 20px;
    }
`;

const LearnMore = styled.button`
display: block;
margin: 40px auto 0px;
border: 3px solid rgb(17, 37, 68);
border-radius: 6px;
padding: 20px 50px;
background: white;
font-size: 18px;
font-weight: bold;
font-family: Poppins, sans-serif;

&:hover {
  background: rgb(172, 193, 174);
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  padding: 10px 30px;
}

a{
  color: rgb(17, 37, 68);
  text-decoration: none;
}
`;

const IndexPage = () => {
  return (
    <main>
      <Helmet>
      <title>Dunning Currie Insurance Inc.</title>
      <meta name="description" content="Dunning Currie Insurance Inc. is an independently owned Canadian Property &amp; Casualty premier insurance brokerage. " />
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      <meta charSet="utf-8" />
      </Helmet>
      <Navigation />
      <HeroSection>
        <div>
         <HeroImg src={Hero} alt="hero image" />
         <Heading>Dunning Currie Insurance Inc</Heading>
         <Mission>
         DCI's managing partners have a combined <Emphasis>35+ years</Emphasis> in the industry giving them the passion, relationships and experience to ensure comprehensive, <Emphasis>tailored solutions </Emphasis>
          with <Emphasis>impeccable service</Emphasis> for all their&nbsp;clients.
         <br />
         <br />
         DCI has all the big business tools with the attention and care that only an independent, passionate shop can&nbsp;provide.
         <LearnMore><a href="/about">Learn More</a></LearnMore>
         </Mission>
         </div>
      </HeroSection>
      <section>
      <iframe src="https://snazzymaps.com/embed/414928" width="100%" height="400px"></iframe>
      </section>
      <Footer />
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Dunning Currie Insurance Inc</title>
